import React, { Fragment } from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

export const Footer = () => {
  return (
    <Fragment>
      <div className='section-dark-thin'></div>
      <div className='footer'>
        <Row justify='center' gutter={[32, 32]}>
          <Col xl={3} lg={4} md={6} sm={12} xs={12}>
            <h5>Patients</h5>
            <ul className='header-footer-list'>
              <li>
                <Link className='btn-footer' to='/patient/assessment'>
                  Take Assessment
                </Link>
              </li>
              <li>
                <Link className='btn-footer' to='/patient/pathway'>
                  Patient Pathway
                </Link>
              </li>
              <li>
                <Link className='btn-footer' to='/about/contact'>
                  Find a Clinic
                </Link>
              </li>
            </ul>
          </Col>
          <Col xl={3} lg={4} md={6} sm={12} xs={12}>
            <h5>Research</h5>
            <ul className='header-footer-list'>
              <li>
                <Link className='btn-footer' to='/research/roadmap'>
                  Roadmap
                </Link>
              </li>
            </ul>
          </Col>
          <Col xl={3} lg={4} md={6} sm={12} xs={12}>
            <h5>Fellowship</h5>
            <ul className='header-footer-list'>
              <li>
                <Link className='btn-footer' to='/fellowship/message'>
                  A Message from the Director
                </Link>
              </li>
              <li>
                <Link className='btn-footer' to='/fellowship/past-fellows'>
                  Past Fellows
                </Link>
              </li>
              <li>
                <Link className='btn-footer' to='/fellowship/testimonials'>
                  Testimonials
                </Link>
              </li>
              <li>
                <Link className='btn-footer' to='/fellowship/apply'>
                  Apply
                </Link>
              </li>
            </ul>
          </Col>
          <Col xl={3} lg={4} md={6} sm={12} xs={12}>
            <h5>About</h5>
            <ul className='header-footer-list'>
              <li>
                <Link className='btn-footer' to='/about/message'>
                  A Message from the Chairman
                </Link>
              </li>
              <li>
                <Link className='btn-footer' to='/about/team'>
                  Meet the Team
                </Link>
              </li>
              <li>
                <Link className='btn-footer' to='/about/contact'>
                  Contact
                </Link>
              </li>
              <li>
                <Link className='btn-footer' to='/about/careers'>
                  Careers
                </Link>
              </li>
              <li>
                <Link className='btn-footer' to='/about/documents'>
                  Documents
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row justify='center' align='middle' gutter={16}>
          <Col xl={3}>
            <picture>
              <source srcSet={'/images/spine-program-logo.webp'} type='image/webp' />
              <img
                src='/images/spine-program-logo.png'
                className='logo-img-small'
                alt='uofc'
              />
            </picture>
          </Col>
        </Row>
        <div className='text-center' style={{ marginTop: '2rem' }}>
          <span className='muted'>
            &copy; {dayjs().year()} University of Calgary Spine Program
          </span>
        </div>
      </div>
    </Fragment>
  )
}
